import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"

const PhotosVideos = () => {
  return (
    <Layout>
      <div>This is the Photos and Videos page</div>
    </Layout>
  )
}

export default PhotosVideos
